define('ember-cognito/services/cognito', ['exports', 'amazon-cognito-identity-js', 'ember-cognito/utils/cognito-storage', 'ember-cognito/utils/cognito-user'], function (exports, _amazonCognitoIdentityJs, _cognitoStorage, _cognitoUser) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    session: Ember.inject.service(),

    willDestroy() {
      this._super(...arguments);
      this.stopRefreshTask();
    },

    // Primarily used so we can stub methods.
    _stubPool(pool) {
      return pool;
    },

    /**
     * Method for signing up a user.
     *
     * @param username User's username
     * @param password Plain-text initial password entered by user.
     * @param attributeList New user attributes.
     * @param validationData Application metadata.
     */
    signUp(username, password, attributeList, validationData) {
      let { poolId, clientId } = this.getProperties('poolId', 'clientId');
      let pool = this._stubPool(new _amazonCognitoIdentityJs.CognitoUserPool({
        UserPoolId: poolId,
        ClientId: clientId,
        Storage: new _cognitoStorage.default({})
      }));

      return new Ember.RSVP.Promise((resolve, reject) => {
        pool.signUp(username, password, attributeList, validationData, (err, result) => {
          if (err) {
            reject(err);
          } else {
            result.user = _cognitoUser.default.create({ user: result.user });
            resolve(result);
          }
        });
      }, `cognito-service#signUp`);
    },

    /**
     * Enable the token refresh timer.
     */
    startRefreshTask(session) {
      if (!this.get('autoRefreshSession')) {
        return;
      }
      // Schedule a task for just past when the token expires.
      const now = Math.floor(new Date() / 1000);
      const exp = session.getIdToken().getExpiration();
      const adjusted = now - session.getClockDrift();
      const duration = (exp - adjusted) * 1000 + 100;
      this.set('_taskDuration', duration);
      this.set('task', Ember.run.later(this, 'refreshSession', duration));
    },

    /**
     * Disable the token refresh timer.
     */
    stopRefreshTask() {
      Ember.run.cancel(this.get('task'));
      this.set('task', undefined);
      this.set('_taskDuration', undefined);
    },

    refreshSession() {
      let user = this.get('user');
      if (user) {
        return this.get('session').authenticate('authenticator:cognito', { state: { name: 'refresh' } });
      }
    }
  });
});